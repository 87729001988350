import React from "react";
import "./SocialMedia.css";
import { socialMediaLinks } from "../../portfolio";
import styled from "styled-components";
import { Icon } from '@iconify/react';
const IconWrapper = styled.span`
  i {
    background-color: ${(props) => props.backgroundColor};
  }
  &:hover i {
    background-color: ${({ theme }) => theme.text};
    transition: 0.3s ease-in;
  }
`;

export default function socialMedia(props) {
  return (
    <div className="social-media-div">
      {socialMediaLinks.map((media) => {
        return (
          <a
            href={media.link}
            className={`icon-button`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper {...media} {...props}>
              {media.fontAwesomeIcon == "upwork" ? (
                <i style={{height:'41.6px', width:'41.6px',display:"inline-block"}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.7 56.7" margin-top="10px">
                  <path d="M0 0v56.7h56.7V0H0zm20.3 14.6h5.6a48.4 48.4 0 0 0 5.6 12.1c1.6-5.5 5.6-9 10.9-9a11.3 11.3 0 0 1 0 22.5 14 14 0 0 1-7.7-2.2l-2.4 11.9h-5.7l3.5-16.3a51.5 51.5 0 0 1-4-6.7v2.5a10.9 10.9 0 0 1-21.8 0V14.7h5.4v14.6a5.3 5.3 0 1 0 10.6 0V14.6zm22.2 8.8c-4.1 0-5.4 4-5.8 6.4v.1l-.6 2.2a10.2 10.2 0 0 0 6.3 2.5 5.9 5.9 0 0 0 5.7-5.6 5.6 5.6 0 0 0-5.6-5.6z"/></svg>
                  </i>
              ) : <i className={`fab ${media.fontAwesomeIcon}`}></i>}
            </IconWrapper>
            {/* <span></span> */}
          </a>
        );
      })}
    </div>
  );
}
